<template>
  <div>
    <!-- 右边盒子存在就是绝对定位，不存在就是float --> 
    <div
      :class="statisticsRight ? 'mapLeft' : 'mapLeftAbout'"
      :style="{ width: fullState ? '0' : '300px' }"
    >
      <!-- <el-input
          clearable
          placeholder="请输入人员姓名"
          prefix-icon="el-icon-search"
          v-model="PersonAjaxData.name"
          size="mini"
          style="width: 70%; margin-right: 10px"
        >
        </el-input> 
        <el-button @click="SearchPersonFun" size="mini">查询</el-button>-->
      <div class="headTitle">
        <div>巡检人员</div>
        <div>
          <span @click="SearchPersonFun(1)">只看在线</span>/<span
            @click="SearchPersonFun(2)"
            >查看全部</span
          >
          <i
            class="el-icon-circle-close"
            style="margin: 0px 10px; font-size: 16px"
            @click="clone()"
          ></i>
        </div>
      </div>
      <div style="height: auto; overflow: auto" class="EventItemBody">
        <div
          v-for="item in PersonList"
          @click="PersonClickFun(item)"
          :key="item.id"
          class="EventItemClass"
        >
          <div class="imagess">
            <img :src="item.avatar" style="width: 100%; height: 100%" />
          </div>
          <div>
            <div style="font-size: 14px; display: flex; flex-direction: row">
              姓名：{{ item.username }}
              <div
                class="stratbox"
                :style="{
                  background: item.online == 0 ? '#2C9EF7' : '#cccccc',
                }"
              >
                {{ item.online == 0 ? "在线" : "离线" }}
              </div>
            </div>
            <div style="font-size: 14px">手机号：{{ item.mobile }}</div>
            <!-- <div style="font-size: 14px">
              状态：{{ item.online == 0 ? "在线" : "离线" }}
            </div> -->
            <div style="font-size: 14px; margin-top: 5px">
              {{ item.eventAddress }}
            </div>
            <div class="EventItemBottom"></div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
      <subMap
        ref="subMap"
        :layer-name="layerName"
        :rules="rules"
        :layers="layers"
        :layer-code="layerCode"
        @setLocation="setLocation"
      />
      <!-- <networkForm ref="formBox" :showForm="showForm" @save="save" @closePDraw="closePDraw" :addForm='addForm'/> -->
    </div>
  </div>
</template>

<script>
import { LoadMixin } from "@/common/amapLoad";
import subMap from "@/views/mapTool/subMapLine";
import { eventList, personList } from "@/RequestPort/maintenance/task";
export default {
  name: "GisMap",
  components: { subMap },
  // networkForm
  mixins: [LoadMixin],
  data() {
    return {
      name: "片区",
      pagerCount: 3,
      rules: {
        networkName: [
          { required: true, message: "请输入片区名称", trigger: "blur" },
          {
            min: 0,
            max: 100,
            message: "长度在 0 到 100 个字符",
            trigger: "blur",
          },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "change" }],
      },
      layerName: "gas:gis_view",
      layerCode: "",
      pageParams: {
        current: 1,
        size: 100,
        type: 1,
      },
      total: 0,
      areaData: [],
      tableData: [],
      // 人员列表
      PersonList: [],
      // 侧边栏
      fullState: true,
      // 统计右边栏
      statisticsRight: false,
      PersonAjaxData: {
        name: "",
        onlineState: 2,
        trackState: "",
        current: 1,
        size: 100000,
      },
      layers: "'patrol_events','patrol_lines','user','patrol_equipments'",
    };
  },
  computed: {},
  destroyed() {
    this.$eventBus.$off("PersonListed");
  },
  mounted() {
    this.$eventBus.$on("PersonListed", (data) => {
      this.fullState = !this.fullState;
    });
    // 加载人员列表
    this.PersonListFun();
  },
  methods: {
    clone() {
      this.$eventBus.$emit("PersonListed");
    },
    /**
     * 点击收回（右侧菜单展示）
     */
    PutawayRight() {
      this.statisticsRight = !this.statisticsRight;
    },
    
    /**
     * 加载人员列表
     */
    PersonListFun() {
      personList(this.PersonAjaxData).then((e) => {
        this.PersonList = e.data.records;
      });
    },
    /**
     * 查询人员列表
     */
    SearchPersonFun(val) {
      // this.PersonAjaxData  条件变换
      this.PersonAjaxData.onlineState = val;
      this.PersonListFun();
    },
    /**
     * 点击查看人员位置详情
     */
    PersonClickFun(item) {
      this.$eventBus.$emit("PersonClickFuned", item);
    },
   
    addPoint() {
      this.$message.success("请先在地图上点选位置，然后填写右侧表单");
      this.$refs.subMap.getLocation("polygon");
    },
    setLocation(path) {
      console.log(path);
      // let path=[
      //   ...point,
      //   point[0]
      // ]
      // this.addForm.location=point
      // this.showForm=true
      let that = this;
    },
  },
};
</script>

<style lang="less" scoped>
.mapLeftAbout {
  height: calc(100vh - 100px);
  width: 300px;
  background: #fff;
  float: right;
  // position: absolute;
  // right: 0;
  // z-index: 9;
  overflow: auto;
  // 文字表头
  .headTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #cccccc;
    span {
      color: #2c9ef7;
    }
  }
  // 人员盒子
  .EventItemClass {
    padding: 10px;
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: row;
    .imagess {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .stratbox {
      width: 60px;
      text-align: center;
      margin-left: 10px;
      color: #ffffff;
    }
  }
  .EventItemClass:hover {
    background-color: #eee;
  }
}
.mapLeft {
  height: calc(100vh - 60px);
  width: 300px;
  background: #fff;
  float: right;
  overflow: auto;
  // 文字表头
  .headTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #cccccc;
    span {
      color: #2c9ef7;
    }
  }
  // 人员盒子
  .EventItemClass {
    padding: 10px;
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: row;
    .imagess {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .stratbox {
      width: 60px;
      text-align: center;
      margin-left: 10px;
      color: #ffffff;
    }
  }
  .EventItemClass:hover {
    background-color: #eee;
  }
}
// 统计右边
.statisticsRightMap {
  height: calc(100vh - 60px);
  width: 350px;
  background: #fff;
  float: right;
  overflow: auto;
  .title {
    height: 42px;
    line-height: 42px;
    padding-left: 20px;
    background: #ebedf5;
    color: rgba(12, 35, 91, 1);
    font-size: 16px;
  }
  // 图
  .echarts {
    width: 90%;
    height: 200px;
    margin: 20px auto;
  }
  // 方式统计
  .Manner {
    display: flex;
    justify-content: space-between;
    height: 80px;
    margin: 0 20px;
    border-bottom: 1px solid #cccccc;
    .label {
      width: 60px;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      margin: 25px 0;
      text-align: center;
      color: #ffffff;
      border-radius: 5px;
      background: #409eff;
    }
    .number {
      height: 80px;
      line-height: 80px;
      font-size: 30px;
      font-weight: bold;
      color: #0c235b;
    }
  }
}
.topbt {
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}
.mapRight {
  width: auto;
  margin-left: 0px;
  position: relative;
  overflow: hidden;
}
.pageBox {
  margin: 15px;
  text-align: center;
}
.lineBox {
  margin: 15px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 8px;
  .lineP {
    margin-top: 5px;
  }
  .lineB {
    margin-top: 10px;
  }
}
.searchForm {
  margin: 15px 15px 0 5px;
}
.searchBt {
  text-align: center;
}
.formBox {
  position: absolute;
  right: 0;
  top: 100px;
  background: #fff;
  width: 300px;
  padding-top: 10px;
  .t {
    padding-left: 10px;
    font-size: 16px;
  }
  ::v-deep {
    .el-divider--horizontal {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
  }
}
// 右侧
.PutawayRight {
  background: #737e95;
  position: absolute;
  top: calc((100% - 3rem) / 2);
  color: var(--title-text-color);
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  z-index: 8;
  height: 0.56rem;
}

&::v-deep {
  .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 4px;
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 6px;
    height: 50px;
    background: rgba(0, 0, 0, 0.2); //滚动条颜色
  }
  .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #eee; //滚动条背景色
  }
  .statisticsRightMap::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 4px;
  }
  .statisticsRightMap::-webkit-scrollbar-thumb {
    border-radius: 6px;
    height: 50px;
    background: rgba(0, 0, 0, 0.2); //滚动条颜色
  }
  .statisticsRightMap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #eee; //滚动条背景色
  }
}
</style>
